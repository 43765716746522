import React, { useEffect, useState } from "react";
import { supabase } from "../helper/supabaseClient";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import secureLocalStorage from "react-secure-storage";
import { useDarkMode } from "../context/darkModeContext";
import { api } from "../utils/axios-instance";
import axios from "axios";

const currentDateTime = () => {
  //  calculate time and date for imput field
  const time =
    new Date().toISOString().slice(0, 11) +
    new Date().toTimeString().slice(0, 5);
  return time;
};

const rescheduleDate = (date) => {
  // calculate new rescheduled timing
  return parseInt((new Date(date).getTime() - new Date().getTime()) / 1000);
};

const DueDate = () => {
  const [dues, setDues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDue, setSelectedDue] = useState(null);
  const [extendedDate, setExtendedDate] = useState(currentDateTime());
  const [reschedule, setReshedule] = useState(false);
  const [newExpiry, setnewExpiry] = useState(rescheduleDate(currentDateTime()));
  const [timeInterval, setTimeInterval] = useState(null);
  const { darkMode } = useDarkMode();

  let role = JSON.parse(secureLocalStorage.getItem("profileData"));
  const isOrgAdmin = role && role.role_priv === "org_admin";

  // ...............

  const fetchDueDates = async () => {
    try {
      const res = await api.get("/file/getDueDates/");

      setDues(res.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching due dates:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDueDates();
  }, []);

  const handleExtendedDate = (e) => {
    setExtendedDate(e.target.value);
    setnewExpiry(rescheduleDate(e.target.value));
  };

  const updateDueDate = async (Id) => {
    // let token = JSON.parse(secureLocalStorage.getItem("token"));
    // let token = secureLocalStorage.getItem("token");
    // get time difference in seconds
    setnewExpiry(rescheduleDate(extendedDate));
    let body = {
      expiration_time: newExpiry,
      last_updated: new Date().toJSON(),
    };
    toggleReshedule();
    setTimeout(() => setSelectedDue(null), 100);
    try {
      // const res = await axios.patch(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}/file/editShare/${Id}/`, body, {
      //   headers: {
      //     // Authorization: `Bearer ${token.session.access_token}`,
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // handling the firebaseauth
      const res = await api.patch(`/file/editShare/${Id}/`, body);
      const updatedDues = dues.map((i) => {
        if (i.file === res.data.file) {
          // removing extra parameters
          const { owner, security_check, ...x } = res.data;
          return x;
        }
        return i;
      });

      setDues(updatedDues);
      secureLocalStorage.setItem("dueDatesCache", JSON.stringify(updatedDues));
    } catch (error) {
      console.log(error);
    }
  };

  const skeletons = Array.from({ length: 4 }, (_, i) => (
    <div
      key={i}
      className="border shadow p-3 my-2 rounded-lg flex items-center gap-2"
    >
      <Skeleton
        key={i}
        variant="rounded"
        width={24}
        height={24}
        className="mr-2"
      />
      <Skeleton className="w-1/5" height={28} />
      <Skeleton className="w-1/5" height={25} />
      <Skeleton className="w-2/5" height={28} />
    </div>
  ));

  const convertSecondsToDaysHours = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);

    let result = "";
    if (days > 0) {
      result += `${days} ${days === 1 ? "day" : "days"}`;
    }

    if (hours > 0) {
      result += ` ${hours} ${hours === 1 ? "hour" : "hours"}`;
    }

    return result.trim();
  };

  function convertDateFormat(originalDateString) {
    // Parse the original date string
    const originalDate = new Date(originalDateString);

    // Extract day, month, and year
    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1; // Months are zero-based
    const year = originalDate.getUTCFullYear();

    // Format the date components with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Assemble the formatted date string
    const formattedDateString = `${formattedDay} / ${formattedMonth} / ${year}`;

    return formattedDateString;
  }

  function convertTimeFormat(createdAt, expirationTime) {
    // Get the current date
    const now = new Date();
    const createdAtDate = new Date(createdAt);

    // Calculate the expiration date
    const expirationDate = new Date(
      createdAtDate.getTime() + expirationTime * 1000
    );

    // Calculate the difference in milliseconds
    const timeLeftMs = expirationDate - now;

    // Convert to the highest possible unit
    const timeLeftInSeconds = Math.floor(timeLeftMs / 1000);
    const timeLeftInMinutes = Math.floor(timeLeftMs / (1000 * 60));
    const timeLeftInHours = Math.floor(timeLeftMs / (1000 * 60 * 60));
    const timeLeftInDays = Math.floor(timeLeftMs / (1000 * 60 * 60 * 24));
    const timeLeftInYears = Math.floor(
      timeLeftMs / (1000 * 60 * 60 * 24 * 365)
    );

    // Display the result in the highest possible unit
    let timeLeft;
    if (timeLeftInYears > 0) {
      timeLeft = `${timeLeftInYears} year(s)`;
    } else if (timeLeftInDays > 0) {
      timeLeft = `${timeLeftInDays} day(s)`;
    } else if (timeLeftInHours > 0) {
      timeLeft = `${timeLeftInHours} hour(s)`;
    } else if (timeLeftInMinutes > 0) {
      timeLeft = `${timeLeftInMinutes} minute(s)`;
    } else {
      timeLeft = `${timeLeftInSeconds} second(s)`;
    }

    return timeLeft;
  }

  const toggleReshedule = () => {
    reschedule ? setReshedule(false) : setReshedule(true);
  };

  return (
    <div className="w-full md:w-4/5">
      <Paper className="h-[25rem]">
        <div
          className={`flex justify-between items-center p-5 ${
            darkMode ? "bg-gray-600 text-gray-200 " : " "
          }`}
        >
          <p className="text-sm font-bold text-gray-500">Due Date</p>
        </div>
        <div className="h-80 px-4 overflow-y-scroll scrollbar-hide">
          {!loading ? (
            dues.length ? (
              dues?.map((due, index) => {
                const sharedWithUserId = due?.shared_with || "";
                // const sharedWith = due?.security_details?.selective_access[sharedWithUserId];
                const sharedWith = Object.keys(due?.security_details || {})
                  .length
                  ? due?.security_details?.selective_access[sharedWithUserId]
                  : {};
                return (
                  <div
                    key={index}
                    className="border shadow p-3 my-2 rounded-lg flex flex-col "
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isOrgAdmin) {
                        setSelectedDue(due);
                      } else {
                        console.log(
                          "You are not authorised to reschedule due dates"
                        );
                      }
                    }}
                  >
                    <div className="flex flex-row">
                      <Tooltip title={sharedWith?.email} arrow className="mr-2">
                        <Avatar
                          src={sharedWith?.profilePictureUrl}
                          alt="owner pic"
                          sx={{ width: 25, height: 25 }}
                          variant="rounded"
                        />
                      </Tooltip>
                      <p>
                        <strong className="font-semibold">
                          {sharedWith?.name} {sharedWith?.last_name}
                        </strong>
                        's access to{" "}
                        <strong className="font-semibold">
                          {due?.name?.split("_TS=")[0]}
                        </strong>{" "}
                        is ends in{" "}
                        {convertTimeFormat(
                          due?.created_at,
                          due?.expiration_time
                        )}
                      </p>
                    </div>

                    {selectedDue && selectedDue.id === due?.id && (
                      <div className="px-3 py-1 ">
                        <p className="text-sm font-bold">File Due Date</p>
                        <div className="border border-gray-200 rounded-lg w-full py-1 px-3 my-2 shadow">
                          <p className="text-sm font-semibold">
                            File access will restricted to receiver:
                          </p>
                          <span className="flex flex-row items-center gap-1">
                            <span className="border-2 border-gray-200 rounded-full h-6 my-auto px-2">
                              <p className="text-xs whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                                Last Updated
                              </p>
                            </span>
                            <span className="bg-[#F1F1FF] w-full rounded-sm flex justify-between px-2 py-1 my-2">
                              <p className="text-sm">
                                {convertDateFormat(selectedDue.last_updated)}
                              </p>
                            </span>
                          </span>
                        </div>
                        <div className="flex justify-between px-5 py-3 my-2">
                          <button
                            onClick={() => {
                              toggleReshedule(true);
                              setnewExpiry(selectedDue.last_updated);
                            }}
                            className={`text-[white] rounded-lg cursor-pointer py-2 px-4 text-sm bg-[#5E5ADB] ${
                              reschedule ? "hidden" : ""
                            }`}
                          >
                            Re-schedule
                          </button>
                          <div
                            className={`flex flex-col justify-center ${
                              reschedule ? "" : "hidden"
                            }`}
                          >
                            <label
                              htmlFor="extendDate"
                              className="font-bold py-1 px-4"
                            >
                              Select New Schedule :{" "}
                            </label>
                            <div className="flex justify-left gap-3 px-5 py-3 my-2">
                              <input
                                className="bg-[#F1F1FF] py-1 px-4 rounded-lg"
                                type="datetime-local"
                                value={extendedDate}
                                onChange={handleExtendedDate}
                                name="extendDate"
                                id="extendDate"
                                min={currentDateTime()}
                              />
                              <span className="bg-[#F1F1FF] flex gap-1 items-center w-auto px-4 py-1 rounded-lg">
                                <strong>Ends in : </strong>
                                <p className="text-sm">
                                  {convertSecondsToDaysHours(newExpiry)}
                                </p>
                              </span>
                            </div>
                            <div className="flex justify-end gap-3 items-center px-6 py-2">
                              <button
                                onClick={() => {
                                  setSelectedDue(null);
                                  toggleReshedule(false);
                                }}
                                className="text-[white] rounded-lg  cursor-pointer py-2 px-4  text-sm bg-[#5E5ADB]"
                              >
                                Close
                              </button>
                              <button
                                onClick={() => {
                                  updateDueDate(selectedDue.id);
                                }}
                                className="text-[white] rounded-lg py-2 cursor-pointer px-4 text-sm bg-[#5E5ADB]"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="text-center">No due dates!</div>
            )
          ) : (
            <div className="overflow-y-scroll scrollbar-hide">{skeletons}</div>
          )}
        </div>
      </Paper>
      <script>
        {document.body.addEventListener("click", () => {
          if (selectedDue) setSelectedDue(null);
        })}
      </script>
    </div>
  );
};

export default DueDate;
