import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RangesDirective,
  RangeDirective,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-spreadsheet";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import useAuth from "../../stores/authStore";
import { auth, storage } from "../../helper/firebaseClient";
import fileContext from "../../context/fileContext";
import handleTextExtraction from "../../helper/fileExtraction";

import {
  CLEAN,
  LEFT,
  LOWER,
  MATCH,
  MID,
  MROUND,
  RIGHT,
  STDEV,
  SUBSTITUTE,
  TRIM,
  UPPER,
} from "../../utils/spreadSheetFormulas";
import { api } from "../../utils/axios-instance";
import { generateFileVersionId } from "../../utils/helpers";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWXxfdnRSQmJcVkR2WEY="
);

const CACHE_NAME = "blob-cache";

const Spread = ({
  preUrl,
  mimetype,
  fileName,
  fileId,
  setSaving,
  securityDetails,
  currVersion,
}) => {
  if (!preUrl) alert("Please provide a valid URL");
  const spreadsheetRef = useRef(null);
  const profileData = useAuth((state) => state.profileData);
  const { profileIsPending } = useAuth((state) => state.profileData);


  console.log({ profileData });
  const deptId = useMemo(() => profileData?.dept ?? null, [profileData]);
  // eslint-disable-next-line no-unused-vars
  const [_, setUploadProgress] = useState(0);
  const context = useContext(fileContext);
  const userAccess = securityDetails?.selective_access[profileData.id].access;

  const beforeSave = (args) => {
    spreadsheetRef.current.hideSpinner();

    args.needBlobData = true; // To trigger the saveComplete event.
    args.isFullPost = false; // Get the spreadsheet data as blob data in the saveComplete event.
  };

  const saveComplete = (args) => {
    spreadsheetRef.current.hideSpinner();
    let reader = new FileReader();
    reader.readAsArrayBuffer(args.blobData);
    reader.onloadend = function () {
      const arrayBuffer = reader.result;
      const fileBuffer = new Uint8Array(arrayBuffer);
      saveFile(fileBuffer);
    };
  };

  const fetchFileData = useCallback(() => {
    try {
      let spreadSheet = spreadsheetRef.current;

      fetch(preUrl)
        .then((response) => response.blob())
        .then((fileBlob) => {
          let file = new File([fileBlob], "Sample.xlsx");
          spreadSheet.open({ file: file });
        });
    } catch (error) {
      console.error("Error fetching or processing the file:", error);
    } finally {
      spreadsheetRef.current.hideSpinner();
    }
  }, [preUrl]);

  useEffect(() => {
    fetchFileData();
  }, [preUrl, mimetype, fetchFileData]);

  useEffect(() => {
    const spreadSheet = spreadsheetRef.current;
    if (spreadSheet) {
      // Register all the custom formuals
      spreadSheet.addCustomFunction(MROUND, "MROUND");
      spreadSheet.addCustomFunction((...args) => {
        return STDEV(...args, spreadSheet);
      }, "STDEV");
      // spreadSheet.addCustomFunction((...args) => {
      //   return VLOOKUP({
      //     spreadsheet: spreadSheet,
      //     value: args[0],
      //     range: args[1],
      //     columnIndex: args[2],
      //     isSorted: args[3] ?? "FALSE",
      //   });
      // }, "VLOOKUP");
      // spreadSheet.addCustomFunction(
      //   (lookupValue, range, columnIndex, isSorted) => {
      //     return HLOOKUP({
      //       spreadsheet: spreadSheet,
      //       value: lookupValue,
      //       range: range,
      //       columnIndex: columnIndex,
      //       isSorted: isSorted,
      //     });
      //   },
      //   "HLOOKUP"
      // );
      spreadSheet.addCustomFunction((text) => {
        return UPPER(text);
      }, "UPPER");
      spreadSheet.addCustomFunction((text) => {
        return LOWER(text);
      }, "LOWER");
      spreadSheet.addCustomFunction((text) => {
        return TRIM(text);
      }, "TRIM");
      spreadSheet.addCustomFunction((text) => {
        return CLEAN(text);
      }, "CLEAN");
      spreadSheet.addCustomFunction((...args) => {
        return SUBSTITUTE(...args);
      }, "SUBSTITUTE");
      spreadSheet.addCustomFunction((...args) => {
        return LEFT(...args);
      }, "LEFT");
      spreadSheet.addCustomFunction((...args) => {
        return RIGHT(...args);
      }, "RIGHT");
      spreadSheet.addCustomFunction((...args) => {
        return MID(...args);
      }, "MID");
    }
  }, [spreadsheetRef]);

  useEffect(() => {
    const handleTabClose = () => {
      handleTextExtraction(
        context.currFile,
        fileId,
        fileName,
        "red",
        profileData,
        true
      );
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const saveFile = (fileBuffer) => {
    spreadsheetRef.current.hideSpinner();

    const file = new Blob([fileBuffer], { type: mimetype });
    if (!fileId) {
      alert("Please provide a valid file ID");
      return;
    }

    context.setCurrFile(file);
    uploadFile(file)
      .then(async () => {
        console.log("File saved successfully.", { file });
        const token = await auth.currentUser.getIdToken();
        const fileIdentfier = `${fileId}-${token}`;
        // update file version
        const fileVersionId = generateFileVersionId(fileId);
        await api.post(`file/update-file-version/${fileId}/`, {
          versionId: fileVersionId,
          idToken: token,
        });
        // update cache
        const cache = await caches.open(CACHE_NAME);
        const responseToCache = new Response(file, {
          headers: {
            "Content-Type": mimetype,
            "sw-cache-date": new Date().toISOString(),
            "x-file-version-id": fileVersionId,
          },
        });
        await cache.put(fileIdentfier, responseToCache);
        console.log("Cache updated for file:", fileIdentfier);
        setSaving(false);
      })
      .catch((error) => {
        console.error("File save error:", error);
      });
  };

  const uploadFile = async (file) => {
    return new Promise((resolve, reject) => {
      const fileRef = ref(storage, `files/${profileData.org}/${currVersion}`);
      const metadata = {
        customMetadata: {
          department_id: deptId || "default",
          org_id: profileData.org,
        },
      };
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("File upload error:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  if (profileIsPending) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <p>Please wait...</p>
      </div>
    );
  }

  const Editorhieght = window.innerHeight - 64;

  let userCanEdit = true;

  if (userAccess && !userAccess.includes("edit")) {
    userCanEdit = false;
  }

  return (
    <SpreadsheetComponent
      openUrl="https://services.syncfusion.com/react/production/api/spreadsheet/open"
      ref={spreadsheetRef}
      beforeSave={beforeSave}
      saveComplete={saveComplete}
      actionComplete={async (cell) => {
        const spreadSheet = spreadsheetRef.current;
        if (cell.action === "cellSave") {
          setSaving(true);
          spreadSheet.save({
            url: "https://services.syncfusion.com/react/production/api/spreadsheet/save",
          });
          spreadSheet.hideSpinner();
        }
      }}
      style={{
        // width: "100%",
        // height: "100%",
        padding: 0,
      }}
      height={context.isMultiSelect ? "80vh" : Editorhieght}
      allowSave={userCanEdit}
      saveUrl="https://services.syncfusion.com/react/production/api/spreadsheet/save"
      allowEditing={userCanEdit}
      enableContextMenu={userCanEdit}
    >
      <SheetsDirective>
        <SheetDirective name="Car Sales Report">
          <RangesDirective>
            <RangeDirective></RangeDirective>
          </RangesDirective>
          <ColumnsDirective>
            <ColumnDirective width={180}></ColumnDirective>
            <ColumnDirective width={130}></ColumnDirective>
            <ColumnDirective width={130}></ColumnDirective>
            <ColumnDirective width={180}></ColumnDirective>
            <ColumnDirective width={130}></ColumnDirective>
            <ColumnDirective width={120}></ColumnDirective>
          </ColumnsDirective>
        </SheetDirective>
      </SheetsDirective>
    </SpreadsheetComponent>
  );
};

export default Spread;
