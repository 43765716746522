import React, { useEffect, useRef } from "react";

import "../../styles/ImageEditor_styles.css";

import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
import { registerLicense } from "@syncfusion/ej2-base";
import { auth, storage } from "../../helper/firebaseClient";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useAuth from "../../stores/authStore";
import { generateFileVersionId } from "../../utils/helpers";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWXxfdnRSQmJcVkR2WEY="
);

const CACHE_NAME = "blob-cache";

const ImageEditor = ({ preUrl, fileName, fileId, setSaving, mimetype }) => {
  const imageEditorRef = useRef(null);
  const profileData = useAuth((state) => state.profileData);

  useEffect(() => {
    if (imageEditorRef.current) {
      const fetchImage = async () => {
        const response = await fetch(preUrl);

        if (!response.ok) {
          throw new Error("Failed to fetch the Word file");
        }

        const blob = await response.blob();

        // Convert blob to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          imageEditorRef.current.open(base64data); // Assuming the editor accepts base64 encoded data
        };
        reader.readAsDataURL(blob); // Convert blob to base64 string
      };
      fetchImage();
    }
  }, [fileName, preUrl, mimetype]);

  const handleSave = () => {
    setSaving(true); // Set the saving state to true

    const imageData = imageEditorRef.current.getImageData();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.height = imageData.height;
    canvas.width = imageData.width;
    ctx.putImageData(imageData, 0, 0);

    canvas.toBlob(async (blob) => {
      console.log(blob);
      const file = new File([blob], fileName, { type: mimetype });
      const fileRef = ref(storage, `files/${profileData.org}/${fileId}`);
      const metadata = {
        customMetadata: {
          department_id: profileData.dept,
          org_id: profileData.org,
        },
      };

      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File available at:", downloadURL);
          const fileVersionId = generateFileVersionId(fileId);

          const token = await auth.currentUser.getIdToken();
          const fileIdentifier = `${fileId}-${token}`;

          // Cache the file

          const cache = await caches.open(CACHE_NAME);
          const responseToCache = new Response(file, {
            headers: {
              "Content-Type": mimetype,
              "sw-cache-date": new Date().toISOString(),
              "x-file-version-id": fileVersionId,
            },
          });
          await cache.put(fileIdentifier, responseToCache);
          console.log("Cache updated for file:", fileIdentifier);
          setSaving(false);
        }
      );
    });
  };

  const toolbar = [
    "Annotate",
    "Finetune",
    "Filter",
    "Confirm",
    "Reset",
    "Save",
    "ZoomIn",
    "ZoomOut",
    "Crop",
    { text: "Save" },
  ];

  return (
    <>
      <ImageEditorComponent
        ref={(img) => {
          imageEditorRef.current = img;
        }}
        height="calc(100vh - 64px)"
        width="100vw"
        toolbarItemClicked={(args) => {
          if (args.item.text === "Save") {
            handleSave();
          }
        }}
        name={fileName}
        alt={fileName}
        enablePersistence={true}
        toolbar={toolbar}
      />
    </>
  );
};

export default ImageEditor;
