import React, { useEffect, useRef } from "react";

import "../../styles/PdfEditor_styles.css";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { registerLicense } from "@syncfusion/ej2-base";
import { auth, storage } from "../../helper/firebaseClient";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useAuth from "../../stores/authStore";
import { generateFileVersionId } from "../../utils/helpers";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWXxfdnRSQmJcVkR2WEY="
);

const CACHE_NAME = "blob-cache";

const PdfEditor = ({ preUrl, fileId, fileName, setSaving }) => {
  const viewerRef = useRef(null);
  const Editorhieght = window.innerHeight - 60;
  const profileData = useAuth((state) => state.profileData);

  // Fetch and open the pdf
  useEffect(() => {
    const fetchAndExtractContent = async (pdfFileUrl) => {
      try {
        // Fetch the pdf file
        const response = await fetch(pdfFileUrl);
        const pdfBlob = await response.blob();

        // Convert data to base64
        const base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = (error) => reject(error);
        });

        // Wait for the viewer to be ready before loading the PDF
        if (viewerRef.current) {
          setTimeout(() => {
            const viewer =
              document.getElementById("container")?.ej2_instances[0];

            if (viewer) {
              viewer.load("data:application/pdf;base64," + base64data, null);
            } else {
              console.error("Viewer is not initialized.");
            }
          }, 1500);
        }
      } catch (error) {
        console.error("Error while fetching the pdf file: ", error);
      }
    };

    if (preUrl) {
      fetchAndExtractContent(preUrl);
    }
  }, [preUrl]);

  var saveButton = {
    id: "savetofirebase",
    text: "Save",
    tooltipText: "Save to Cloud",
    align: "right",
  };

  async function toolbarClick(args) {
    const viewer = document.getElementById("container")?.ej2_instances[0];

    if (viewer) {
      if (args.item && args.item.id === "savetofirebase") {
        setSaving(true);
        const blob = await viewer.saveAsBlob(fileName);
        const file = new File([blob], fileName, { type: "application/pdf" });
        // Save to firebase

        const fileRef = ref(storage, `files/${profileData.org}/${fileId}`);
        const metadata = {
          customMetadata: {
            department_id: profileData.dept,
            org_id: profileData.org,
          },
        };

        const uploadTask = uploadBytesResumable(fileRef, file, metadata);

        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("File upload error:", error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("File available at:", downloadURL);

            const token = await auth.currentUser.getIdToken();
            const fileIdentifier = `${fileId}-${token}`;
            const fileVersionId = generateFileVersionId(fileId);

            // Cache the file
            const cache = await caches.open(CACHE_NAME);
            const responseToCache = new Response(file, {
              headers: {
                "Content-Type": "application/pdf",
                "sw-cache-date": new Date().toISOString(),
                "x-file-version-id": fileVersionId,
              },
            });
            await cache.put(fileIdentifier, responseToCache);
            setSaving(false);
          }
        );
      }
    }
  }

  return (
    <div style={{ height: Editorhieght + "px", width: "100vw" }}>
      <PdfViewerComponent
        id="container"
        ref={viewerRef}
        resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
        height={Editorhieght + "px"}
        width="100%"
        enablePrint={true}
        toolbarClick={toolbarClick}
        toolbarSettings={{
          showTooltip: true,
          toolbarItems: [
            saveButton,
            "OpenOption",
            "PageNavigationTool",
            "MagnificationTool",
            "PanTool",
            "SelectionTool",
            "SearchOption",
            "PrintOption",
            "DownloadOption",
            "UndoRedoTool",
            "AnnotationEditTool",
            "FormDesignerEditTool",
            "CommentTool",
            "SubmitForm",
            "OrganizePagesTool",
          ],
        }}
      >
        <Inject
          services={[
            Toolbar,
            Magnification,
            Navigation,
            Annotation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            FormFields,
            FormDesigner,
          ]}
        />
      </PdfViewerComponent>
    </div>
  );
};

export default PdfEditor;
